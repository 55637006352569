import { useRef, useEffect, useState } from "react";

import MapContainer from "./components/MapContainer/MapContainer";
import ChatContainer from "./components/ChatContainer/ChatContainer";
import TripExpired from "./components/TripExpired/TripExpired";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import PageVisibility from 'react-page-visibility';

import sessionConfig from "./data/config";

var expireIntervalHandle = -1;
var parsed = parseUrl(window.location.href);
var pathParts = parsed.pathname.replace(/\/$/, "").split("/");
var customerCode = null;
if (pathParts.length > 2) {
  customerCode = pathParts[2];
  sessionConfig.url += customerCode;
}

function App() {
  var mapRef = useRef();
  var chatRef = useRef();
  const [inProcess, setInProcess] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [showMap, setShowMap] = useState(false);
  const [toastIsSuccess, setToastIsSuccess] = useState(true);
  const [toast, settoast] = useState("");

  function processExpiry(tobeExpiredAfter, disableChat) {
    if (tobeExpiredAfter > 1) {
      if (expireIntervalHandle !== -1) return;
      expireIntervalHandle = setInterval(function () {
        if (mapRef.current) mapRef.current.unsubscribeEvents();
        setShowMap(false);
      }, tobeExpiredAfter);
    }
    if (disableChat) {
      if (!chatRef.current) {
        setTimeout(() => {
          if (chatRef.current) {
            chatRef.current.disableChat(disableChat === 1);
          } else {
            processExpiry(tobeExpiredAfter, disableChat);
          }
        }, 100);
      } else chatRef.current.disableChat(disableChat === 1);
    }
  }

  function handleVisibilityChange(isVisible) {
    if(isVisible) {
      window.location.reload();
    }
  }

  function showToasts(isSuccess, message) {
    settoast(message);
    setToastIsSuccess(isSuccess);
    setTimeout(function () {
      setToastIsSuccess(true);
      settoast("");
    }, sessionConfig.toastr.duration);
  }

  useEffect(() => {
    if (inProcess > 0) return;
    if (!customerCode) {
      setInProcess(2);
      return;
    }
    setInProcess(1);
    fetch(sessionConfig.url, {
      method: "GET",
      cache: "no-cache",
    })
      .then((response) => response.json())
      .then((userInfo) => {
        if (!userInfo.lat || !userInfo.long) {
          userInfo.lat = sessionConfig.mapbox.current_center[0];
          userInfo.long = sessionConfig.mapbox.current_center[1];
        }
        
        if (!userInfo.linkExpired) {
          setUserInfo(userInfo);
          setShowMap(true);
          if (userInfo.expiryTimeStamp > 0) {
            var expireAfter = userInfo.expiryTimeStamp - new Date().getTime();
            if (expireAfter > 0) {
              console.log(new Date(userInfo.expiryTimeStamp));
              processExpiry(expireAfter);
            } else if (
              userInfo.status &&
              ["COMPLETE", "CANCEL"].indexOf(userInfo.status.toUpperCase()) > -1
            ) {
              processExpiry(0, 2);
            }
          }
        }

        setInProcess(2);
      })
      .catch((error) => {
        setInProcess(2);
        console.error("Error:", error);
      });
  });

  if (inProcess > 1) {
    if (showMap) {
      return (
        <div>
          <MapContainer
            ref={mapRef}
            customerCode={customerCode}
            userInfo={userInfo}
            processExpiry={processExpiry}
          />
          <div className={toast.length > 0 ? "toastr show" : "toastr"}>
            {toastIsSuccess ? (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="16" cy="16" r="16" fill="#15B765" />
                <path
                  d="M13.9429 22.3192C13.7979 22.3192 13.6624 22.2619 13.5593 22.1589L8.20139 16.7991C7.99146 16.591 7.99146 16.2475 8.20139 16.0396L9.36719 14.8718C9.46839 14.7707 9.60379 14.7135 9.74692 14.7135C9.89005 14.7135 10.0255 14.7707 10.1286 14.8718L13.939 18.7014L21.8709 10.7599C21.974 10.6569 22.1075 10.6016 22.2527 10.6016C22.3956 10.6016 22.5312 10.6569 22.6342 10.7599L23.8 11.9257C24.008 12.1338 24.008 12.4773 23.8 12.6851L14.3206 22.1646C14.2195 22.2619 14.086 22.3192 13.9429 22.3192L13.9429 22.3192Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="16" cy="16" r="16" fill="white" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M32 16C32 24.8385 24.8359 32 16 32C7.16406 32 0 24.8385 0 16C0 7.16665 7.16406 0 16 0C24.8359 0 32 7.16665 32 16ZM16 19.2258C14.361 19.2258 13.0323 20.5545 13.0323 22.1936C13.0323 23.8326 14.361 25.1613 16 25.1613C17.639 25.1613 18.9677 23.8326 18.9677 22.1936C18.9677 20.5545 17.639 19.2258 16 19.2258ZM13.1824 8.55832L13.661 17.3325C13.6834 17.7431 14.0228 18.0645 14.434 18.0645H17.566C17.9772 18.0645 18.3167 17.7431 18.339 17.3325L18.8176 8.55832C18.8418 8.11484 18.4887 7.74193 18.0446 7.74193H13.9554C13.5112 7.74193 13.1582 8.11483 13.1824 8.55832Z"
                  fill="#E82370"
                />
              </svg>
            )}
            <span className="toast-msg">{toast}</span>
          </div>
          {/* <ChatContainer
            customerCode={customerCode}
            showToasts={showToasts}
            ref={chatRef}
          /> */}
          <PageVisibility onChange={handleVisibilityChange}>
          </PageVisibility>
        </div>
      );
    } else {
      return <TripExpired />;
    }
  } else {
    return (
      <Loader
        className="centre"
        type="ThreeDots"
        color="#3d3d3d"
        height={100}
        width={100}
        // timeout={100000}
      />
    );
  }
}

function parseUrl(url) {
  var m = url.match(
      /^(([^:\/?#]+:)?(?:\/\/((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/
    ),
    r = {
      hash: m[10] || "", // #asd
      host: m[3] || "", // localhost:257
      hostname: m[6] || "", // localhost
      href: m[0] || "", // http://username:password@localhost:257/deploy/?asd=asd#asd
      origin: m[1] || "", // http://username:password@localhost:257
      pathname: m[8] || (m[1] ? "/" : ""), // /deploy/
      port: m[7] || "", // 257
      protocol: m[2] || "", // http:
      search: m[9] || "", // ?asd=asd
      username: m[4] || "", // username
      password: m[5] || "", // password
    };
  if (r.protocol.length === 2) {
    r.protocol = "file:///" + r.protocol.toUpperCase();
    r.origin = r.protocol + "//" + r.host;
  }
  r.href = r.origin + r.pathname + r.search + r.hash;
  return r;
}

export default App;
