import {
  forwardRef,
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import * as turf from "@turf/turf";
import API from "@aws-amplify/api";

import polyline from "@mapbox/polyline";

import "react-toggle/style.css";
import Toggle from "react-toggle";

import sessionConfig from "../../data/config";
import * as graphqlSubscriptions from "../../graphql/subscriptions";

import "mapbox-gl/dist/mapbox-gl.css";
import "./MapContainer.css";

mapboxgl.accessToken = sessionConfig.mapbox.access_token;

API.configure(sessionConfig.aws.appsync.credentials);

var userName,
  prevDriveLoc,
  prevDriveStatus,
  liveLocationCoords,
  liveLocationMarker,
  liveLocationMarkerElement,
  customerCode,
  driverIconName,
  currentSessionInfo,
  isCruise,
  processExpiry,
  prevPoint,
  destPopup,
  geolocationWatchId,
  centerForLiveLocation;
var linestring = sessionConfig.lineString;
var currentCenter = sessionConfig.mapbox.current_center;
var geolocation = window.navigator.geolocation;
var positionOptions = {
  enableHighAccuracy: true,
  timeout: 10 * 1000, // 10 seconds
  maximumAge: 30 * 1000, // 30 seconds
};
centerForLiveLocation = true;

const MapContainer = forwardRef((data, ref) => {
  processExpiry = data.processExpiry;
  customerCode = data.customerCode;
  currentSessionInfo = data.userInfo;

  if (currentSessionInfo.mode) {
    var driveMode = currentSessionInfo.mode.toLowerCase();
    isCruise = driveMode === "cruise";
  } else {
    isCruise = false;
  }

  let subscription, markerObj, liveLocationMarkerObj;

  let currentZoom = sessionConfig.mapbox.zoom;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(currentCenter[1]);
  const [lat, setLat] = useState(currentCenter[0]);
  const [zoom, setZoom] = useState(currentZoom);
  const [rideStatus, setRideStatus] = useState("");
  const [rideStatusShort, setRideStatusShort] = useState("");

  const [showUserLocation, setShowUserLocation] = useState(false);
  let toShowLiveLocation;

  useImperativeHandle(ref, () => ({
    unsubscribeEvents() {
      console.log("Unsubscribing from map");
      if (subscription) subscription.unsubscribe();
      if (map.current) {
        map.current.remove();
        map.current = null;
        mapContainer.current = null;
      }
    },
  }));

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: sessionConfig.mapbox.style_url,
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.on("load", function () {
      addEvents(map.current, setRideStatus, setRideStatusShort, setShowUserLocation, toggleShareLiveLocations);
    });

    async function setupSubscription() {
      subscription = API.graphql({
        query: graphqlSubscriptions.refreshUserTripLocation,
        variables: { tripId: customerCode },
        authMode: "API_KEY",
      }).subscribe({
        next: (res) => {
          var newLoc = res.value.data.refreshUserTripLocation;

          // REMOVE once this is coming through stream
          if (!newLoc.isLiveLocationShared) newLoc.isLiveLocationShared = true;

          if (destPopup && newLoc.arrivalTime) {
            currentSessionInfo.arrivalTime = newLoc.arrivalTime;
            localStorage.setItem(
              `${customerCode}_arrivalTime`,
              newLoc.arrivalTime
            );
            destPopup.setHTML(dropOffDecorate());
          }

          let changePuckPos = false;
          let changeDriveStatus = newLoc.status === prevDriveStatus;

          newLoc.longitude = parseFloat(newLoc.longitude);
          newLoc.latitude = parseFloat(newLoc.latitude);

          if (prevDriveLoc) {
            let distPrevToCur = 0;
            var arr = [];

            arr.push(prevDriveLoc);
            arr.push([newLoc.longitude, newLoc.latitude]);
            linestring.geometry.coordinates = arr;
            distPrevToCur = parseFloat(
              turf.length(linestring).toLocaleString()
            );
            changePuckPos = distPrevToCur > 0.01;
          } else {
            prevDriveLoc = [newLoc.longitude, newLoc.latitude];
            changePuckPos = true;
          }

          var s = newLoc.status;
          var markerClass = null,
            iconName = null;
          if (s && s.length > 0) {
            s = s.toLowerCase();
            if (Object.keys(sessionConfig.message_maps).indexOf(s) < 0) {
              return;
            }
            if (s === "pause" || s === "complete") {
              markerClass = "marker-inactive";
              iconName = "sharing-inactive";

              // if (map.current.getLayer("routeline-active"))
              //   map.current.removeLayer("routeline-active");
              // if (map.current.getSource("route"))
              //   map.current.removeSource("route");
            } else {
              markerClass = "marker";
              iconName = "sharing-active";
            }

            if (s === "complete" || s === "cancel") {
              processExpiry(sessionConfig.expireAfter * 60 * 1000, 2);
            }
          }
          if (!markerClass) {
            return;
          }

          setRideStatus(
            processStatus(newLoc.status, newLoc.isLiveLocationShared)
          );
          setRideStatusShort(newLoc.status.toLowerCase());
          resetCenter(newLoc.longitude, newLoc.latitude, map.current);

          if (!newLoc.isLiveLocationShared) {
            if (markerObj) {
              map.removeLayer(markerObj);
            }
            return;
          }

          var curPoint = [
            parseFloat(newLoc.longitude),
            parseFloat(newLoc.latitude),
          ];
          let point;
          if (changePuckPos) {
            // if (map.current.getLayer("point")) map.current.removeLayer("point");
            // if (map.current.getSource("point"))
            //   map.current.removeSource("point");

            point = {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: curPoint,
                  },
                },
              ],
            };
            if(driverIconName !== iconName) {
              driverIconName = iconName;
              if (iconName) {
                if (map.current.getLayer("point")) map.current.removeLayer("point");
                if (map.current.getSource("point")) map.current.removeSource("point");
              }
            }
            if (!map.current.getSource("point")){
              map.current.addSource("point", {
                type: "geojson",
                data: point,
              });
              map.current.addLayer({
                id: "point",
                source: "point",
                type: "symbol",
                layout: {
                  "icon-image": iconName,
                  "icon-rotate": ["get", "bearing"],
                  "icon-rotation-alignment": "map",
                  "icon-allow-overlap": true,
                  "icon-ignore-placement": true,
                },
              });
            }
            else {
              map.current.getSource("point").setData(point);
            }
          }

          centerForLiveLocation = (
            s === "complete" ||
            s === "cancel" ||
            s === "pause"
          );

          if (isCruise) {
            if (prevPoint) {
              // point.features[0].properties.bearing = turf.bearing(
              //   turf.point(prevPoint),
              //   turf.point(curPoint)
              // );
              map.current.getSource("point").setData(point);
            }
            prevPoint = curPoint;
            return;
          }

          if (centerForLiveLocation || !changePuckPos){
            return;
          }
          //newLoc.coordinates = undefined;
          if(!newLoc.coordinates || newLoc.coordinates === null) {
            newLoc.coordinates = localStorage.getItem(`trip_${customerCode}_coordinates`);
          }
          const dest = [currentSessionInfo.long, currentSessionInfo.lat];
          if (newLoc.coordinates && newLoc.coordinates !== null) {
            localStorage.setItem(`trip_${customerCode}_coordinates`, newLoc.coordinates);
            let routeCoords = polyline.decode(newLoc.coordinates, 6);
            routeCoords = routeCoords.map((rt) => {
              return [rt[1], rt[0]];
            });
            const distanceFromEnd = measureDistance(
              dest,
              routeCoords[routeCoords.length - 1]
            );
            const distanceFromStart = measureDistance(dest, routeCoords[0]);
            console.log(
              `distanceFromEnd: ${distanceFromEnd}, distanceFromStart: ${distanceFromStart}`
            );

            if (distanceFromEnd < distanceFromStart) {
              routeCoords = routeCoords.reverse();
            }

            const routeFeaturePoints = routeCoords.map((p) => turf.point(p));
            const routeFeatureCollections =
              turf.featureCollection(routeFeaturePoints);
            let nearestPoint = turf.nearestPoint(
              curPoint,
              routeFeatureCollections
            );
            nearestPoint = nearestPoint.geometry.coordinates;
            let lastIndex = -1;
            routeCoords.map((c, index) => {
              if (c[0] === nearestPoint[0] && c[1] === nearestPoint[1]) {
                lastIndex = index;
              }
            });
            if (lastIndex >= 0) {
              routeCoords = routeCoords.slice(0, lastIndex);
            }

            routeCoords.push(curPoint);
            routeCoords = { coordinates: routeCoords, type: "LineString" };

            addRoute(map.current, routeCoords);
          } else {
            var coordsStr = curPoint.join(",");
            coordsStr += ";";
            coordsStr += dest.join(",");
            // Create the query
            var query =
              "https://api.mapbox.com/matching/v5/mapbox/driving/" +
              coordsStr +
              "?geometries=geojson&radiuses=25;25&steps=true&access_token=" +
              mapboxgl.accessToken;

            fetch(query)
              .then((response) => response.json())
              .then((data) => {
                // Get the coordinates from the response
                var coords = data.matchings[0].geometry;

                var ln = coords.coordinates.length;
                var lastCoord = coords.coordinates[ln - 2];
                // point.features[0].properties.bearing = turf.bearing(
                //   turf.point(coords.coordinates[ln - 2]),
                //   turf.point(coords.coordinates[ln - 1])
                // );
                map.current.getSource("point").setData(point);

                // Draw the route on the map
                addRoute(map.current, coords);
              });
          }
        },
      });
    }

    if (currentSessionInfo.isLiveLocationShared) setupSubscription();
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  const toggleShareLiveLocations = (e) => {
    //showUserLocation = !showUserLocation;
    toShowLiveLocation = e.target.checked;
    setShowUserLocation(toShowLiveLocation);
    localStorage.setItem("showUserLocation", toShowLiveLocation);
    if (!map || !toShowLiveLocation) {
      if (liveLocationMarker) {
        liveLocationMarker.remove();
        liveLocationMarker = undefined;
        liveLocationMarkerElement = undefined;
      }
      if(geolocationWatchId) {
        clearInterval(geolocationWatchId);
        geolocationWatchId = undefined;
      }
      return;
    }
    if (geolocation) {
      geolocationWatchId = geolocation.watchPosition(
        (position) => {
          if (!position || !position.coords || !toShowLiveLocation) {
            return;
          }
          var coords = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          if (!liveLocationMarker) {
            liveLocationMarkerElement = document.createElement("div");
            liveLocationMarker = new mapboxgl.Marker(liveLocationMarkerElement);
          }
          liveLocationMarkerElement.className = "live-location-marker";
          var curPoint = [
            parseFloat(coords.longitude),
            parseFloat(coords.latitude),
          ];
          if(centerForLiveLocation){
            resetCenter(
              curPoint[0],
              curPoint[1],
              map.current,
              undefined,
              liveLocationCoords
            );
          }
          liveLocationCoords = curPoint;
          liveLocationMarkerObj = liveLocationMarker
            .setLngLat(curPoint)
            .addTo(map.current);
          liveLocationMarkerObj.togglePopup();
        },
        (positionError) => {
          console.log("Error happened: " + positionError.message);
        },
        positionOptions
      );
    }
  }; 

  return (
    <div>
      <div
        className={
          rideStatusShort +
          " sidebar " +
          (isCruise ? "sidebar-cruise" : "sidebar-navigation")
        }
      >
        {rideStatus}
        {!isCruise ? (
          <div></div>
        ) : (
          <div className="share-live-location">
            <span className="item-icon"></span>
            <span className="item-text">
              Show my live location on the map <br />
              <span>( You are the only one can view it )</span>
            </span>
            <span className="item-toggle">
              <Toggle
                id="share-live-location-id"
                checked={showUserLocation}
                aria-labelledby="share-live-location"
                onChange={toggleShareLiveLocations}
              />
            </span>
          </div>
        )}
      </div>
      <div ref={mapContainer} className="map-container" />
    </div>
  );
});

// Draw the Map Matching route as a new layer on the map
function addRoute(map, coords) {
  if (isCruise) {
    return;
  }

  // If a route is already loaded, remove it
  if (map.getSource("route")) {
    map.getSource("route").setData(coords);
    // map.removeLayer('route')
    // map.removeSource('route')
  } else {
    // Add a new layer to the map
    map.addSource("route", {
      type: "geojson",
      data: coords,
    });
    map.addLayer(
      {
        id: "routeline-active",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#782EB1",
          "line-width": ["interpolate", ["linear"], ["zoom"], 12, 1, 40, 12],
        },
      },
      "waterway-label"
    );

    // map.addLayer(
    //   {
    //     id: "routearrows",
    //     type: "symbol",
    //     source: "route",
    //     layout: {
    //       "symbol-placement": "line",
    //       "text-field": "▶",
    //       "text-size": ["interpolate", ["linear"], ["zoom"], 12, 24, 22, 60],
    //       "symbol-spacing": [
    //         "interpolate",
    //         ["linear"],
    //         ["zoom"],
    //         12,
    //         30,
    //         22,
    //         160,
    //       ],
    //       "text-keep-upright": false,
    //     },
    //     paint: {
    //       "text-color": "#782EB1",
    //       "text-halo-color": "hsl(55, 11%, 96%)",
    //       "text-halo-width": 3,
    //     },
    //   },
    //   "waterway-label"
    // );
  }
}

function addEvents(map, setRideStatus, setRideStatusShort, 
  setShowUserLocation, toggleShareLiveLocations) {
  currentSessionInfo.long = parseFloat(currentSessionInfo.long);
  currentSessionInfo.lat = parseFloat(currentSessionInfo.lat);
  resetCenter(currentSessionInfo.long, currentSessionInfo.lat, map, true);
  
  let showUserLocationFromLocalStorage = localStorage.getItem("showUserLocation");
  setShowUserLocation((showUserLocationFromLocalStorage === "true")); 
  toggleShareLiveLocations({
    target: {
      checked: (showUserLocationFromLocalStorage === "true")
    }
  });

  if (!currentSessionInfo.name) {
    userName = "Howie";
  } else {
    userName = currentSessionInfo.name;
  }

  setRideStatus(
    processStatus(
      currentSessionInfo.status,
      currentSessionInfo.isLiveLocationShared
    )
  );

  setRideStatusShort(currentSessionInfo.status.toLowerCase());

  if (isCruise) {
    return;
  }

  var dest = [currentSessionInfo.long, currentSessionInfo.lat];
  var dropoffs = sessionConfig.dropoffs;

  dropoffs.features[0].properties.description = dropOffDecorate();
  dropoffs.features[0].properties.name = currentSessionInfo.destination;
  dropoffs.features[0].geometry.coordinates = [
    currentSessionInfo.long,
    currentSessionInfo.lat,
  ];

  map.addSource("dropoffs", {
    type: "geojson",
    data: dropoffs,
  });
  map.addLayer(sessionConfig.dropoffsLayer);
  destPopup = new mapboxgl.Popup({
    closeOnClick: false,
    closeButton: false,
    offset: [0, -20],
    className: "dest-popup",
  });
  destPopup.setLngLat(dest);
  destPopup.setHTML(dropoffs.features[0].properties.description);
  destPopup.addTo(map);

  map.on("click", function (e) {
    console.log(e.lngLat);
  });
}

function dropOffDecorate() {
  var description =
    "<strong>" +
    currentSessionInfo.destination +
    "</strong><p>" +
    (currentSessionInfo.destinationAddress1
      ? currentSessionInfo.destinationAddress1
      : "Singapore") +
    "</p>";

  let arrivalTime = localStorage.getItem(`${customerCode}_arrivalTime`);
  if (!(arrivalTime && arrivalTime !== null)) {
    arrivalTime = currentSessionInfo.arrivalTime;
  }
  if (arrivalTime) {
    description += "<span>Arrival Time: " + arrivalTime + "</span>";
  }
  return description;
}

function processStatus(status, isLiveLocationShared) {
  if (!isLiveLocationShared) {
    return sessionConfig.message_maps["isNotSharingLocation"];
  }
  if (status && status.length > 1) {
    var s = status.toLowerCase();
    if (sessionConfig.message_maps[s]) {
      status = sessionConfig.message_maps[s].replace(
        "<driver>",
        currentSessionInfo.name
      );
    }
  }
  return status;
}

function measureDistance(pointA, pointB) {
  let arr = [];
  arr.push(pointA);
  arr.push(pointB);
  linestring.geometry.coordinates = arr;
  const distance = parseFloat(turf.length(linestring).toLocaleString());
  return distance;
}

function resetCenter(lng, lat, map, calculateDistance, prevLiveLocationCoords) {
  var distance = 0;
  var curPoint = [lng, lat];
  if (!calculateDistance) {
    var arr = [];
    // if (prevLiveLocationCoords) arr.push(prevLiveLocationCoords);
    // else
    arr.push(currentCenter);
    arr.push(curPoint);
    linestring.geometry.coordinates = arr;
    distance = parseFloat(turf.length(linestring).toLocaleString());
  }
  calculateDistance = calculateDistance || distance > 0.05;

  // var distArr = [];
  // distArr.push([103.7200300, 1.352390000]);
  // distArr.push(curPoint);
  // linestring.geometry.coordinates = arr;
  // console.log('Distance: ' + turf.length(linestring).toLocaleString());

  //console.log('Distance: ' + distance);

  var zoom = map.getZoom();

  if (calculateDistance) {
    map.flyTo({
      zoom: zoom,
      center: curPoint,
    });
    currentCenter = curPoint;
  }
}

export default MapContainer;
