import "./TripExpired.css";
import sessionConfig from "../../data/config";

function TripExpired() {
  return (
    <div className="centre">
      <p>
        <svg
          width="59"
          height="59"
          viewBox="0 0 59 59"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.5 0.921875C13.7129 0.921875 0.921875 13.7129 0.921875 29.5C0.921875 45.2871 13.7129 58.0781 29.5 58.0781C45.2871 58.0781 58.0781 45.2871 58.0781 29.5C58.0781 13.7129 45.2871 0.921875 29.5 0.921875ZM40.158 36.9902L37.8533 39.8711C37.7021 40.0602 37.5151 40.2176 37.303 40.3345C37.0909 40.4513 36.8579 40.5252 36.6172 40.5519C36.3765 40.5787 36.133 40.5578 35.9004 40.4904C35.6678 40.423 35.4508 40.3104 35.2617 40.1592L27.541 34.4297C27.0016 33.9978 26.5661 33.4501 26.2669 32.8272C25.9677 32.2043 25.8124 31.522 25.8125 30.831V12.9062C25.8125 12.4173 26.0068 11.9483 26.3525 11.6025C26.6983 11.2568 27.1673 11.0625 27.6562 11.0625H31.3438C31.8327 11.0625 32.3017 11.2568 32.6475 11.6025C32.9932 11.9483 33.1875 12.4173 33.1875 12.9062V29.5L39.8711 34.3975C40.0603 34.5488 40.2178 34.7359 40.3346 34.9482C40.4514 35.1604 40.5252 35.3936 40.5519 35.6344C40.5785 35.8752 40.5575 36.1189 40.4899 36.3515C40.4223 36.5841 40.3095 36.8012 40.158 36.9902Z"
            fill="#979797"
          />
        </svg>
      </p>
      <p>{sessionConfig.message_maps.expired}</p>
    </div>
  );
}

export default TripExpired;
