/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const messageInbox = /* GraphQL */ `
  subscription MessageInbox($tripId: String!) {
    messageInbox(tripId: $tripId) {
      message
      tripId
    }
  }
`;
export const refreshUserTripLocation = /* GraphQL */ `
  subscription RefreshUserTripLocation($tripId: String!) {
    refreshUserTripLocation(tripId: $tripId) {
      arrivalTime
      coordinates
      latitude
      longitude
      status
      tripId
    }
  }
`;
