import { useState, forwardRef, useImperativeHandle } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import "./ChatContainer.css";

import * as mutations from "../../graphql/mutations";
import sessionConfig from "../../data/config";

API.configure(sessionConfig.aws.appsync.credentials);

var customerCode;

const processChat = async (msg, showToast) => {
  if (msg && msg.length > 1) {
    try {
      await API.graphql(
        graphqlOperation(mutations.sendMessage, {
          message: msg,
          tripId: customerCode,
        })
      );
      showToast(true, sessionConfig.chat.delivered);
    } catch (error) {
      showToast(false, sessionConfig.chat["not-delivered"]);
    }
  }
};

const ChatContainer = forwardRef((data, ref) => {
  customerCode = data.customerCode;

  const [counter, setCounter] = useState(50);
  const [message, setMessage] = useState("");
  const [enableChat, toggleChat] = useState(true);

  useImperativeHandle(ref, () => ({
    disableChat(flag) {
      toggleChat(flag);
    },
  }));

  return (
    <div className="chat-container">
      <div className="chat-message">
        For the safety of the driver, send only one short message. Should you
        wish to communicate more, please call the driver.
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          processChat(message, data.showToasts);
          setCounter(50);
          setMessage("");
        }}
      >
        <div className="chat-input-container">
          <div className="input">
            <span className="input-counter">{counter}/50</span>
            <input
              type="text"
              placeholder="Write message"
              maxLength="50"
              disabled={enableChat ? "" : "disabled"}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                if (e.target.value) {
                  setCounter(50 - e.target.value.length);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Backspace" || e.key === "Delete") {
                  if (message && message.length === 1) {
                    setCounter(50);
                    setMessage("");
                  }
                }
              }}
            />
          </div>
          <div
            className="btn-send"
            onClick={(e) => {
              e.preventDefault();
              processChat(message, data.showToasts);
              setCounter(50);
              setMessage("");
            }}
          >
            <svg
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              className={
                message.length === 0 ? "btn-send-icon" : "btn-send-icon active"
              }
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19" cy="19" r="19" />
              <path
                d="M27.9918 11.9527L8.85112 17.0747C8.10407 17.2732 7.94482 18.1966 8.51579 18.641L11.8904 21.2549L24.5928 14.9521C24.8365 14.8296 25.0436 15.1726 24.8209 15.3314L13.3918 23.5203L12.6203 26.3995C12.3941 27.2436 13.3244 27.8494 13.989 27.372L17.001 25.2157L20.9573 28.2769C21.4077 28.6276 22.0724 28.4645 22.312 27.9422L29.0274 13.1809C29.3426 12.491 28.7064 11.7608 27.9918 11.9527Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_active"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="38"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#782EB1" />
                  <stop offset="1" stopColor="#782EB1" />
                </linearGradient>
                <linearGradient
                  id="paint0_grey"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="38"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#C1C1C1" />
                  <stop offset="1" stopColor="#C1C1C1" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </form>
    </div>
  );
});

export default ChatContainer;
