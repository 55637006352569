export default {
    expireAfter: 1,
    url: "https://dev.breeze.com.sg/api/v1/trip/eta?tripUUID=",
    mapbox: {
        current_center: [1.2746, 103.799],
        zoom: 15.19,
        style_url: 'mapbox://styles/breezemap/cksdgaac80vhq18nvf28p81mf',
        access_token: 'pk.eyJ1IjoiYnJlZXplbWFwIiwiYSI6ImNraTAyMzV1ajAwYmsycGxyd2F3YjN4NWQifQ.QI1bMjKtgc3NkimrTO7eCg'
    },
    aws: {
        appsync: {
            credentials: {
                "aws_appsync_graphqlEndpoint": "https://hmxxbelqubetrjth4j5i2x4cqm.appsync-api.ap-southeast-1.amazonaws.com/graphql",
                "aws_appsync_region": "ap-southeast-1",
                "aws_appsync_authenticationType": "API_KEY",
                "aws_appsync_apiKey": "da2-4q5wbbm27nh7bayftjhf6drcu4"
            }
        }
    },
    toastr: {
        duration: 10000
    },
    chat: {
        delivered: 'ETA Message sent',
        'not-delivered': 'Failed to send ETA Message'
    },
    message_maps: {
        'init': 'The trip has not yet started',
        'inprogress': "You are viewing a drive in real-time",
        'pause': 'Sharing has been paused',
        'cancel': 'The driver has stopped sharing live location',
        'complete': 'The trip has ended',
        'expired': 'The link has expired',
        'isNotSharingLocation': 'The driver has stopped sharing live location'
    },
    dropoffs: {
        'type': 'FeatureCollection',
        'features': [
            {
                'type': 'Feature',
                'properties': {
                    'icon': 'dest-pink'
                },
                'geometry': {
                    'type': 'Point',
                }
            }
        ]
    },
    point: {
        'type': 'FeatureCollection',
        'features': [
            {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'Point' //,
                    //'coordinates': origin
                }
            }
        ]
    },
    lineString: {
        'type': 'Feature',
        'geometry': {
            'type': 'LineString'
        }
    },
    routeActive: {
        id: 'routeline-active',
        type: 'line',
        source: 'route',
        layout: {
            'line-join': 'round',
            'line-cap': 'round'
        },
        paint: {
            'line-color': '#3887be',
            'line-width': ['interpolate', ['linear'], ['zoom'], 12, 3, 22, 12]
        }
    },
    routeArrows: {
        id: 'routearrows',
        type: 'symbol',
        source: 'route',
        layout: {
            'symbol-placement': 'line',
            'text-field': '▶',
            'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                24,
                22,
                60
            ],
            'symbol-spacing': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                30,
                22,
                160
            ],
            'text-keep-upright': false
        },
        paint: {
            'text-color': '#3887be',
            'text-halo-color': 'hsl(55, 11%, 96%)',
            'text-halo-width': 3
        }
    },
    dropoffsLayer: {
        'id': 'destinations',
        'type': 'symbol',
        'source': 'dropoffs',
        'layout': {
            'icon-image': ['get', 'icon']
        },
        paint: {
            'text-color': '#e86100'
        }
    }
};